<template>
  <section class="section">
    <form class="container-admin" @submit="handleSubmit">
      <b-loading :active.sync="isLoading" />

      <div class="columns is-multiline">
        <div class="column is-full">
          <h3 class="title is-3 has-text-grey">
            Gerenciamento >
            <router-link to="/admin/gerenciar-tarifas" class="has-text-grey">
              Tarifas
            </router-link>
            >
            <router-link to="/admin/gerenciar-tarifa-tipo" class="has-text-grey">
              Tipos de Tarifas
            </router-link>
            >
            <span class="has-text-primary">
              {{ isNew ? 'Cadastrar Tipo de Tarifa' : 'Editar Tipo de Tarifa' }}
            </span>
          </h3>
        </div>
        <b-field label="Hotel" class="column is-full">
          <b-select v-model="form.hotelId" expanded placeholder="Selecione" required>
            <option v-for="hotel in hoteis" :key="hotel.id" :value="hotel.id">
              {{ hotel.nome }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Nome" class="column is-half">
          <b-input type="text" name="nome" v-model="form.nome" placeholder="Digite" required />
        </b-field>
        <b-field label="Max. Adultos" class="column is-one-fourth">
          <b-input type="number" name="max-adultos" v-model="form.maxAdultos" placeholder="Digite" required />
        </b-field>
        <b-field label="Max. Infantil" class="column is-one-fourth">
          <b-input type="number" name="max-infantil" v-model="form.maxInfantil" placeholder="Digite" required />
        </b-field>

        <b-field label="Foto Principal" class="column is-half">
          <label class="upload-label" for="main-picture" style="cursor:pointer">
            <div v-if="!form.banner">
              <b-icon icon="upload" size="is-large" />
              Selecione um arquivo
            </div>
            <img v-else :src="typeof form.banner === 'object' ? form.banner.url : form.banner" alt="Banner pacote" />
          </label>
          <input id="main-picture" type="file" style="display: none" @change="handleBannerFile" />
        </b-field>
        <b-field label="Detalhe" class="column is-half">
          <b-input type="textarea" name="detalhe" v-model="form.detalhe" placeholder="Digite" required rows="11" />
        </b-field>
      </div>

      <hr />
      <h4 class="title is-4 has-text-centered">Facilidades</h4>
      <hr />

      <b-button type="is-primary" class="mb-4" :disabled="disabledButtonSelecionarTodasFacilidades" @click="selecionarTodasAsFacilidades">
        Selecionar todas as facilidades
      </b-button>

      <div class="columns is-multiline" v-for="facilidadeCategoria in facilidadeCategorias" :key="`facilidade-${facilidadeCategoria.id}`">
        <div class="column is-full">
          <h5 class="title is-5">{{ facilidadeCategoria.nome }}</h5>
        </div>
        <div class="column" v-for="facilidade in facilidadeCategoria.facilidades" :key="`facilidade-${facilidade.id}`">
          <b-checkbox v-model="facilidades" :native-value="facilidade.id" class="has-text-weight-semibold">
            {{ facilidade.nome }}
          </b-checkbox>
        </div>
        <div class="column is-full">
          <hr class="my-0" />
        </div>
      </div>

      <h4 class="title is-4 has-text-centered">Anexos</h4>
      <hr />

      <div class="wrapper-gallery">
        <label for="anexos" class="upload-image">
          <b-icon icon="upload" />
        </label>
        <input id="anexos" type="file" multiple style="display: none" @change="handleFiles" />
        <div class="container-image" v-for="(anexo, index) in anexos" :key="index">
          <div class="button-remove-attachment" @click="() => removerAnexo(anexo)">
            <b-icon icon="close" size="is-small" type="is-black" />
          </div>
          <img :src="anexo.url" :alt="`Foto ${index + 1}`" class="thumb-imagens" />
        </div>
      </div>

      <hr />

      <div class="columns">
        <div class="column is-half">
          <router-link to="/admin/gerenciar-tarifa-tipo" class="button is-light is-fullwidth">
            Voltar
          </router-link>
        </div>
        <div class="column is-half">
          <b-button type="is-primary" expanded tag="button" native-type="submit" label="Salvar"> </b-button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import hoteisService from '@/services/hoteisService';
import tarifaTipoService from '@/services/tarifaTipoService';
import facilidadeCategoriaService from '@/services/facilidadeCategoriaService';

export default {
  name: 'EditarTarifaTipo',
  props: {
    id: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {},
      hoteis: [],
      anexos: [],
      facilidades: [],
      facilidadesExistentes: [],
      facilidadeCategorias: [],
    };
  },
  computed: {
    isNew() {
      return !this.id;
    },
    disabledButtonSelecionarTodasFacilidades() {
      const facilidades = this.facilidadeCategorias.flatMap(c => c.facilidades.map(f => f.id));

      return this.facilidades.length === facilidades.length;
    },
  },
  async created() {
    this.isLoading = true;

    await this.getHoteis();
    await this.getFacilidadeCategorias();

    if (!this.isNew) {
      const { data } = await tarifaTipoService.getById(this.id);
      const { anexos, facilidades, ...formData } = data;
      this.form = formData;
      this.anexos = anexos;
      this.facilidadesExistentes = [...facilidades];
      this.facilidades = facilidades.map(f => f.facilidadeId);
    }

    this.isLoading = false;
  },
  methods: {
    async getHoteis() {
      return hoteisService.get().then(({ data }) => {
        this.hoteis = data;
      });
    },
    async getFacilidadeCategorias() {
      return facilidadeCategoriaService.getCategoriaFacilidades().then(({ data }) => {
        this.facilidadeCategorias = data;
      });
    },
    handleFiles(event) {
      this.isLoading = true;

      event.target.files.forEach(file => {
        this.getImagePreviews(file)
          .then(url => {
            this.anexos.push({ file: file, url });
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    async getImagePreviews(file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
        return new Promise((res, rej) => {
          const reader = new FileReader();
          reader.onload = () => {
            res(reader.result);
          };
          reader.onerror = rej;
          reader.readAsDataURL(file);
        });
      }
    },
    async removerAnexo(anexo) {
      try {
        this.isLoading = true;
        const indexOfAnexo = this.anexos.indexOf(anexo);

        if (anexo.id) {
          await tarifaTipoService.deleteAnexo(anexo.id);
        }

        this.anexos.splice(indexOfAnexo, 1);

        this.$buefy.snackbar.open({
          duration: 5000,
          message: 'Anexo removido com sucesso',
          type: 'is-success',
          position: 'is-bottom-right',
          actionText: 'OK',
          queue: false,
        });
      } catch (error) {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: 'Não foi possível remover o anexo',
          type: 'is-danger',
          position: 'is-bottom-right',
          actionText: 'OK',
          queue: false,
        });
      } finally {
        this.isLoading = false;
      }
    },
    mergeFacilidades() {
      const facilidadesMantidas = this.facilidadesExistentes.filter(facilidade => this.facilidades.indexOf(facilidade.facilidadeId) > -1);
      const facilidadesNovas = this.facilidades
        .filter(f => this.facilidadesExistentes.map(facilidade => facilidade.facilidadeId).indexOf(f) === -1)
        .map(id => ({ facilidadeId: id }));

      return [...facilidadesMantidas, ...facilidadesNovas];
    },
    handleBannerFile(event) {
      this.isLoading = true;

      const [file] = event.target.files;

      this.getImagePreviews(file)
        .then(url => {
          this.form.banner = { file: file, url };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async handleSubmit(event) {
      event.preventDefault();

      try {
        this.isLoading = true;

        const data = { ...this.form };
        data.facilidades = this.mergeFacilidades();
        delete data.banner;

        let promise;
        if (this.isNew) {
          const hotel = this.hoteis.find(h => h.id === data.hotelId);
          data.clienteId = hotel.clienteId;

          promise = tarifaTipoService.post(data);
        } else {
          promise = tarifaTipoService.put(data);
        }

        const {
          data: { id },
        } = await promise;

        await this.atualizarAnexos(id);
        await this.atualizarBanner(id);

        const action = this.isNew ? 'cadastrada' : 'editada';
        this.$buefy.snackbar.open(`Tipo de tarifa ${action} com sucesso`);
        this.$router.push('/admin/gerenciar-tarifa-tipo');
      } catch {
        const action = this.isNew ? 'cadastrar' : 'editar';

        this.$buefy.snackbar.open({
          message: `Erro ao ${action} tipo de tarifa`,
          type: 'is-danger',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async atualizarAnexos(id) {
      const novosAnexos = this.anexos.filter(anexo => !anexo.id);

      if (novosAnexos.length > 0) {
        const anexos = novosAnexos.map(n => n.file);
        await tarifaTipoService.postAnexos(id, anexos);
      }
    },
    async atualizarBanner(id) {
      const { banner } = this.form;

      if (banner && typeof banner === 'object') {
        const { file } = banner;
        await tarifaTipoService.postBanner(id, file);
      }
    },
    selecionarTodasAsFacilidades() {
      const facilidades = this.facilidadeCategorias.flatMap(c => c.facilidades.map(f => f.id));

      this.facilidades = facilidades;
    },
  },
};
</script>

<style>
.upload-label {
  width: 100%;
  height: 100%;
  background-color: rgb(224, 224, 224);
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed gray;
  border-radius: 8px;
}

.upload-label div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-label img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.container-image {
  width: 100px;
  height: 100px;
  display: inline-flex;
  margin-right: 8px;
  position: relative;
  margin-bottom: 8px;
}

.wrapper-gallery {
  display: flex;
  flex-wrap: wrap;
}

.upload-image {
  width: 100px;
  height: 100px;
  display: inline-flex;
  margin-right: 8px;
  background-color: rgb(224, 224, 224);
  border: 1px dashed gray;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}

.button-remove-attachment {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgb(218, 218, 218);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 2px;
  cursor: pointer;
}

.thumb-imagens {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}
</style>
